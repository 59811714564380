import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import { Dashboard } from "@mui/icons-material"

import * as shared from "probuild-shared"

const ProjectContentDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))

const DocumentEditingProject = ({
  element,
}: {
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
}) => {
  const { projectName, notAddedText } = element.project || {}
  return (
    <ProjectContentDiv>
      {projectName && <Typography fontWeight="bold">{projectName}</Typography>}
      {notAddedText && (
        <Typography fontStyle="italic" sx={{ pb: 2 }}>
          {notAddedText}
        </Typography>
      )}
      <Dashboard color="primary" />
    </ProjectContentDiv>
  )
}

export default DocumentEditingProject
