import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import * as shared from "probuild-shared"

import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import useItem from "model/items/edit/useItem"
import ItemEditView from "components/views/items/edit/ItemEditView"
import DeleteDialog from "components/dialogs/ConfirmationDialog"
import OverflowMenu from "components/views/generic/OverflowMenu"
import DeleteMenuItem from "components/views/generic/DeleteMenuItem"
import SaveButton from "components/views/generic/SaveButton"
import useSnackbar from "model/snackbar/useSnackbar"
import useNavigateSafe from "model/navigation/useNavigateSafe"

const ItemEditPage = ({ teamKey }: { teamKey: string }) => {
  const { itemKey } = useParams()
  const [navigate, navigateSafeState] = useNavigateSafe()
  const showSnackbar = useSnackbar()
  const observer: shared.com.probuildsoftware.probuild.library.items.ItemEventObserver =
    useMemo(() => {
      return {
        onSaveSuccess(message: string) {
          showSnackbar(message, "success")
          navigate(-1)
        },
        onSaveError(message: string) {
          showSnackbar(message, "error")
        },
        onDeleteSuccess(message: string) {
          showSnackbar(message, "success")
          navigate(-1)
        },
        onDeleteError(message: string) {
          showSnackbar(message, "error")
        },
      }
    }, [showSnackbar, navigate])
  const [viewData, input] = useItem({ teamKey, itemKey, observer })
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const saveChanges = () => {
    input?.save()
  }
  const openDeleteDialog = () => {
    setDeleteDialogOpen(true)
  }
  const deleteConfirmed = () => {
    setDeleteDialogOpen(false)
    input?.delete()
  }
  const hideDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }
  return (
    <NavigationBarPageLayout
      title={viewData?.title}
      barItems={
        <>
          <SaveButton
            text={viewData?.actions.saveButtonText}
            isEnabled={viewData?.status.isModified}
            onClick={saveChanges}
          />
          <OverflowMenu>
            {viewData?.actions.deleteButtonText && (
              <DeleteMenuItem
                text={viewData?.actions.deleteButtonText}
                onClick={openDeleteDialog}
              />
            )}
          </OverflowMenu>
        </>
      }
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <>
          <ItemEditView
            viewData={viewData}
            input={input}
            navigateSafeState={navigateSafeState}
          />
          <DeleteDialog
            open={deleteDialogOpen}
            onConfirm={deleteConfirmed}
            onCancel={hideDeleteDialog}
            dialogViewData={viewData.actions.deleteConfirmationDialog}
          />
        </>
      )}
    </NavigationBarPageLayout>
  )
}

export default ItemEditPage
