import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import ContractEditView from "components/views/contracts/edit/ContractEditView"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"
import OverflowMenu from "components/views/generic/OverflowMenu"
import DeleteMenuItem from "components/views/generic/DeleteMenuItem"
import SaveButton from "components/views/generic/SaveButton"
import useContract from "model/contracts/edit/useContract"

import useSnackbar from "model/snackbar/useSnackbar"
import useNavigateSafe from "model/navigation/useNavigateSafe"

const ContractEditPage = ({ teamKey }: { teamKey: string }) => {
  const { contractKey } = useParams()
  const showSnackbar = useSnackbar()
  const [navigate, navigateSafeState] = useNavigateSafe()
  const observer = useMemo(() => {
    return {
      onSaveSuccess(message: string) {
        showSnackbar(message, "success")
        navigate(-1)
      },
      onSaveError(message: string) {
        showSnackbar(message, "error")
      },
      onDeleteSuccess(message: string) {
        showSnackbar(message, "success")
        navigate(-1)
      },
      onDeleteError(message: string) {
        showSnackbar(message, "error")
      },
    }
  }, [showSnackbar, navigate])
  const [viewData, input] = useContract({
    teamKey,
    contractKey,
    observer,
    isForDocument: false,
  })
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const saveChanges = () => {
    input?.save()
  }
  const openDeleteDialog = () => {
    setDeleteDialogOpen(true)
  }
  const deleteConfirmed = () => {
    setDeleteDialogOpen(false)
    input?.delete()
  }
  const hideDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }
  return (
    <NavigationBarPageLayout
      title={viewData?.title}
      barItems={
        <>
          <SaveButton
            text={viewData?.actions.saveButtonText}
            onClick={saveChanges}
            isEnabled={viewData?.status.isModified}
          />
          <OverflowMenu>
            {viewData?.actions.deleteButtonText && (
              <DeleteMenuItem
                text={viewData?.actions.deleteButtonText}
                onClick={openDeleteDialog}
              />
            )}
          </OverflowMenu>
        </>
      }
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <>
          <ContractEditView
            viewData={viewData}
            input={input}
            navigateSafeState={navigateSafeState}
          />
          <ConfirmationDialog
            open={deleteDialogOpen}
            onConfirm={deleteConfirmed}
            onCancel={hideDeleteDialog}
            dialogViewData={viewData.actions.deleteConfirmationDialog}
          />
        </>
      )}
    </NavigationBarPageLayout>
  )
}

export default ContractEditPage
