import { styled } from "@mui/material/styles"
import { Card, Grid, Divider, ListItemButton } from "@mui/material"

import * as shared from "probuild-shared"

import DocumentEditingText from "components/views/documents/editing/elements/DocumentEditingText"
import DocumentEditingSwitch from "components/views/documents/editing/elements/DocumentEditingSwitch"
import DocumentEditingClient from "components/views/documents/editing/elements/DocumentEditingClient"
import DocumentEditingDate from "components/views/documents/editing/elements/DocumentEditingDate"
import DocumentEditingRepeatItemRow from "components/views/documents/editing/elements/DocumentEditingRepeatItemRow"
import DocumentEditingRepeatItem from "components/views/documents/editing/elements/DocumentEditingRepeatItem"
import DocumentEditingSummary from "components/views/documents/editing/elements/DocumentEditingSummary"
import DocumentEditingRepeatPhotoRow from "components/views/documents/editing/elements/DocumentEditingRepeatPhotoRow"
import DocumentEditingRepeatPhoto from "components/views/documents/editing/elements/DocumentEditingRepeatPhoto"
import DocumentEditingContract from "components/views/documents/editing/elements/DocumentEditingContract"
import DocumentEditingSignature from "components/views/documents/editing/elements/DocumentEditingSignature"
import DocumentEditingSketch from "components/views/documents/editing/elements/DocumentEditingSketch"
import DocumentEditingMarkupOrDiscount from "components/views/documents/editing/elements/DocumentEditingMarkupOrDiscount"
import DocumentEditingProject from "./elements/DocumentEditingProject"
import { useState } from "react"
import { DragIndicator } from "@mui/icons-material"

const HeaderDiv = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
}))

const ElementDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  alignItems: "center",
}))

const StyledDragIcon = styled(DragIndicator)(({ theme }) => ({
  alignSelf: "center",
  marginRight: theme.spacing(2),
}))

const DocumentEditingGroup = ({
  teamKey,
  userKey,
  documentAnswerKey,
  groupElement,
  elements,
  input,
  isFirstGroup,
}: {
  teamKey: string
  userKey: string
  documentAnswerKey: string
  groupElement: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData | null
  elements: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData[]
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
  isFirstGroup: boolean
}) => {
  const [draggingItem, setDraggingItem] = useState<{
    sourceElementKey: string
    sourceRowKey: string
  } | null>(null)
  const handleDragStart = (index: number) => {
    const sourceElementKey = elements[index]?.elementKey || null
    const sourceRowKey = elements[index]?.row?.rowKey || null
    if (sourceElementKey && sourceRowKey) {
      console.log(
        `Dragging sourceElementKey=${sourceElementKey} sourceRowKey=${sourceRowKey}`
      )
      setDraggingItem({ sourceElementKey, sourceRowKey })
    }
  }
  const handleDragOver = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    const targetElementKey = elements[index]?.elementKey
    const targetRowKey = elements[index]?.row?.rowKey
    if (draggingItem?.sourceElementKey === targetElementKey && targetRowKey) {
      event.preventDefault()
      if (draggingItem.sourceRowKey !== targetRowKey) {
        console.log(
          `Dragged sourceRowKey ${draggingItem.sourceRowKey} to ${targetRowKey}`
        )
        input.moveDocumentRow(
          documentAnswerKey,
          draggingItem.sourceElementKey,
          draggingItem.sourceRowKey,
          targetRowKey
        )
      }
    }
  }
  const handleDragEnd = () => {
    setDraggingItem(null)
  }
  return (
    <Grid key={groupElement?.viewKey || "singleGroupKey"} item xs={12}>
      {groupElement?.labelText && (
        <HeaderDiv>{groupElement?.labelText}</HeaderDiv>
      )}
      <Card>
        <Grid container>
          {elements.map((element, index) => {
            const isFirstElement = isFirstGroup && element === elements[0]
            return (
              <Grid
                key={element.viewKey}
                item
                xs={12}
                onDragStart={() => handleDragStart(index)}
                onDragOver={(event) => handleDragOver(event, index)}
                onDragEnd={() => handleDragEnd()}
              >
                <ElementWithActionArea
                  teamKey={teamKey}
                  userKey={userKey}
                  documentAnswerKey={documentAnswerKey}
                  element={element}
                  input={input}
                  isFirstElement={isFirstElement}
                  draggable={Boolean(element.row)}
                />
              </Grid>
            )
          })}
        </Grid>
      </Card>
    </Grid>
  )
}

const ElementWithActionArea = ({
  teamKey,
  userKey,
  documentAnswerKey,
  element,
  input,
  isFirstElement,
  draggable,
}: {
  teamKey: string
  userKey: string
  documentAnswerKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
  isFirstElement: boolean
  draggable: boolean
}) => {
  const types =
    shared.com.probuildsoftware.probuild.library.documents.data.types.Types
  const type = element.type
  const isSelectable =
    type === types.SNAPSHOT_TYPE_REPEAT_ITEM_ROW ||
    type === types.SNAPSHOT_TYPE_REPEAT_PHOTO_ROW ||
    type === types.SNAPSHOT_TYPE_MARKUP_OR_DISCOUNT
  const elementWithPadding = (
    <ElementWithPadding
      teamKey={teamKey}
      userKey={userKey}
      documentAnswerKey={documentAnswerKey}
      element={element}
      input={input}
      isFirstElement={isFirstElement}
      draggable={draggable}
    />
  )
  if (isSelectable) {
    const onClick = () => {
      if (type === types.SNAPSHOT_TYPE_MARKUP_OR_DISCOUNT) {
        input.openMarkupOrDiscountDialog(element.elementKey)
      } else if (element.row) {
        input.openRepeatItem(
          documentAnswerKey,
          element.elementKey,
          element.row.rowKey,
          element.row.rowDocumentAnswerKey
        )
      }
    }
    return (
      <ListItemButton divider={draggable} sx={{ p: 0 }} onClick={onClick}>
        {elementWithPadding}
      </ListItemButton>
    )
  } else {
    return elementWithPadding
  }
}

const ElementWithPadding = ({
  teamKey,
  userKey,
  documentAnswerKey,
  element,
  input,
  isFirstElement,
  draggable,
}: {
  teamKey: string
  userKey: string
  documentAnswerKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
  isFirstElement: boolean
  draggable: boolean
}) => {
  const types =
    shared.com.probuildsoftware.probuild.library.documents.data.types.Types
  const type = element.type
  const hasDivider =
    type === types.SNAPSHOT_TYPE_REPEAT_ITEM ||
    type === types.SNAPSHOT_TYPE_REPEAT_ITEM_ROW ||
    type === types.SNAPSHOT_TYPE_REPEAT_PHOTO_ROW
  const isSingleSelectableElement =
    type === types.SNAPSHOT_TYPE_CLIENT ||
    type === types.SNAPSHOT_TYPE_PROJECT ||
    type === types.SNAPSHOT_TYPE_CONTRACT ||
    type === types.SNAPSHOT_TYPE_SIGNATURE
  if (isSingleSelectableElement) {
    return (
      <ElementView
        teamKey={teamKey}
        userKey={userKey}
        documentAnswerKey={documentAnswerKey}
        element={element}
        input={input}
        isFirstElement={isFirstElement}
      />
    )
  } else
    return (
      <>
        <ElementDiv
          draggable={draggable}
          style={{
            display: draggable ? "flex" : undefined,
            width: "100%",
          }}
        >
          {draggable && <StyledDragIcon />}
          <ElementView
            teamKey={teamKey}
            userKey={userKey}
            documentAnswerKey={documentAnswerKey}
            element={element}
            input={input}
            isFirstElement={isFirstElement}
          />
        </ElementDiv>
        {hasDivider && <Divider />}
      </>
    )
}

const ElementView = ({
  teamKey,
  userKey,
  documentAnswerKey,
  element,
  input,
  isFirstElement,
}: {
  teamKey: string
  userKey: string
  documentAnswerKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
  isFirstElement: boolean
}) => {
  const types =
    shared.com.probuildsoftware.probuild.library.documents.data.types.Types
  switch (element.type) {
    case types.SNAPSHOT_TYPE_TEXT:
      return (
        <DocumentEditingText
          documentAnswerKey={documentAnswerKey}
          element={element}
          input={input}
          isFirst={isFirstElement}
        />
      )
    case types.SNAPSHOT_TYPE_CLIENT:
      return (
        <DocumentEditingClient
          teamKey={teamKey}
          documentAnswerKey={documentAnswerKey}
          element={element}
          input={input}
        />
      )
    case types.SNAPSHOT_TYPE_PROJECT:
      return <DocumentEditingProject element={element} />
    case types.SNAPSHOT_TYPE_DATE:
      return (
        <DocumentEditingDate
          documentAnswerKey={documentAnswerKey}
          element={element}
          input={input}
        />
      )
    case types.SNAPSHOT_TYPE_REPEAT_ITEM_ROW:
      return (
        <DocumentEditingRepeatItemRow
          documentAnswerKey={documentAnswerKey}
          element={element}
          input={input}
        />
      )
    case types.SNAPSHOT_TYPE_REPEAT_ITEM:
      return (
        <DocumentEditingRepeatItem
          documentAnswerKey={documentAnswerKey}
          userKey={userKey}
          element={element}
          input={input}
        />
      )
    case types.SNAPSHOT_TYPE_SUMMARY:
      return <DocumentEditingSummary element={element} />
    case types.SNAPSHOT_TYPE_CALCULATION:
      return <DocumentEditingSummary element={element} />
    case types.SNAPSHOT_TYPE_REPEAT_PHOTO_ROW:
      return (
        <DocumentEditingRepeatPhotoRow
          documentAnswerKey={documentAnswerKey}
          element={element}
          input={input}
        />
      )
    case types.SNAPSHOT_TYPE_REPEAT_PHOTO:
      return (
        <DocumentEditingRepeatPhoto
          documentAnswerKey={documentAnswerKey}
          userKey={userKey}
          element={element}
          input={input}
        />
      )
    case types.SNAPSHOT_TYPE_CONTRACT:
      return (
        <DocumentEditingContract
          teamKey={teamKey}
          documentAnswerKey={documentAnswerKey}
          element={element}
          input={input}
        />
      )
    case types.SNAPSHOT_TYPE_SIGNATURE:
      return (
        <DocumentEditingSignature
          documentAnswerKey={documentAnswerKey}
          userKey={userKey}
          element={element}
          input={input}
        />
      )
    case types.SNAPSHOT_TYPE_SWITCH:
      return (
        <DocumentEditingSwitch
          documentAnswerKey={documentAnswerKey}
          element={element}
          input={input}
        />
      )
    case types.SNAPSHOT_TYPE_SKETCH:
      return (
        <DocumentEditingSketch
          documentAnswerKey={documentAnswerKey}
          userKey={userKey}
          element={element}
          input={input}
        />
      )
    case types.SNAPSHOT_TYPE_MARKUP_OR_DISCOUNT:
      return <DocumentEditingMarkupOrDiscount element={element} />
    default:
      return <Card>Unsupported Element Type: {element.type}</Card>
  }
}

export default DocumentEditingGroup
